import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { Roles } from '../model/role.interface';
import { CheckDeviceService } from '../services/check-device.service';

export function HRSettingsGuard(): boolean {
  const router = inject(Router);
  const userService = inject(UserService);
  const checkDevise = inject(CheckDeviceService);

  if (checkDevise.isMobile()) {
    router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
    return false;
  }

  if (userService.role === Roles.recruiter || userService.role === Roles.companyRM) {
    router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
    return false;
  }

  return true;

}
